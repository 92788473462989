import {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';

import debounce from 'shared-utils/src/debounce';

import { getBounds } from './gmaps';

export default ({
  mapEl,
  searchMode,
  activeMarkerData,
  setActiveMarker,
  activePoiMarkerData,
  setActivePoiMarker,
  doShowOnMap,
  highlightedListCard,
  highlightedListCardClick,
  shapeBounds,
  showYouGotAwayMsg,
  setShowYouGotAwayMsg,
  focusOnLocalityMarkers,
  resetSaveSearchConditions,
  shapeAddressBaloon,
  setShapeAddressBaloon,
}) => {
  const [query, setQuery] = useState(null);
  const mountRef = useRef(true);
  // const initialCoords = { center: null, bounds: null };
  const searchModeRef = useRef(null);
  const activeMarkerDataRef = useRef(null);
  const activePoiMarkerDataRef = useRef(null);
  const highlightedMarkerRef = useRef(null);
  const highlightedListCardClickRef = useRef(null);
  const shapeBoundsRef = useRef(null);
  const showYouGotAwayMsgRef = useRef(false);
  const focusOnLocalityMarkersRef = useRef(false);
  const shapeAddressBaloonRef = useRef(null);

  // const computeDistance = (first, second = null) => {
  //   const { geometry: { spherical } } = libraries;

  //   console.log(first, second || initialCoords.current.center);

  //   return Math.floor(spherical.computeDistanceBetween(first, second || initialCoords.current.center)) / 1000;
  // };

  // const computeMaxBBoxDistanceFromCenter = (iCenter, iBounds) => {
  //   const NE = iBounds.getNorthEast();
  //   return Math.ceil(computeDistance(iCenter, NE));
  // };

  const closeAllMapOverlayers = useCallback(() => {
    if (activeMarkerDataRef.current) {
      activeMarkerDataRef.current.unSetActiveMarker();
      setActiveMarker({ uKey: null, activeMarkerData: { close: true } });
    }
    if (highlightedMarkerRef.current) {
      doShowOnMap(null);
    }
    if (activePoiMarkerDataRef.current) {
      activePoiMarkerDataRef.current.unSet();
      setActivePoiMarker({ poiMarkerData: null });
    }
    if (shapeAddressBaloonRef.current) {
      shapeAddressBaloonRef.current.setMap(null);
      setShapeAddressBaloon(null);
    }
  }, [mapEl]);

  const closeCardsAndPois = useCallback(() => {
    if (activeMarkerDataRef.current) {
      activeMarkerDataRef.current.unSetActiveMarker();
      setActiveMarker({ uKey: null, activeMarkerData: { close: true } });
    }

    if (activePoiMarkerDataRef.current) {
      if (activePoiMarkerDataRef.current.close) {
        activePoiMarkerDataRef.current.unSet();
        setActivePoiMarker({ poiMarkerData: null });
      } else {
        activePoiMarkerDataRef.current.close = true;
      }
    }

    if (shapeAddressBaloonRef.current) {
      shapeAddressBaloonRef.current.setMap(null);
      setShapeAddressBaloon(null);
    }
  }, [mapEl]);

  const idleListenerDebounced = debounce(() => {
    // console.log({
    // searchModeRef: searchModeRef.current,
    // activeMarkerDataRef: activeMarkerDataRef.current,
    // highlightedMarkerRef: highlightedMarkerRef.current,
    // highlightedListCardClickRef: highlightedListCardClickRef.current,
    // shapeBounds: shapeBoundsRef.current,
    // }); // debug

    if (
      !searchModeRef.current
      && !activeMarkerDataRef.current
      // && !highlightedMarkerRef.current
      && !highlightedListCardClickRef.current
      && !mountRef.current
      && !focusOnLocalityMarkersRef.current
    ) {
      // const newCenter = mapEl.getCenter();
      const newBounds = mapEl.getBounds();
      if (shapeBoundsRef.current) {
        if (!newBounds.intersects(shapeBoundsRef.current)) {
          setShowYouGotAwayMsg(true);
        } else if (showYouGotAwayMsgRef.current) {
          setShowYouGotAwayMsg(false);
        }
      }
      
      // console.log(computeDistance(newCenter), initialCoords.current.centerToBorder);
      
      // console.log('scateno la query');
      const bounds = getBounds(newBounds);
      // const zoom = mapEl.getZoom();
      // const precision = getPrecisionFromZoom(zoom);
      setQuery({
        geobounds: {
          bbox: bounds,
          // zoom,
        },
        // precision,
      });
      
    } else if (mountRef.current) {
      mountRef.current = false;

      // const iCenter = mapEl.getCenter();
      // const iBounds = mapEl.getBounds();

      // initialCoords.current = {
      //   center: iCenter,
      //   bounds: iBounds,
      //   centerToBorder: computeMaxBBoxDistanceFromCenter(iCenter, iBounds),
      // };
    } else if (highlightedListCardClickRef.current) {
      highlightedListCardClickRef.current = false;
    } else if (focusOnLocalityMarkersRef.current) {
      resetSaveSearchConditions();
    }
  }, 150);

  const idleListener = useCallback(() => idleListenerDebounced(), [mapEl]);

  useEffect(() => {
    searchModeRef.current = searchMode;
    activeMarkerDataRef.current = activeMarkerData?.close ? null : activeMarkerData;
    activePoiMarkerDataRef.current = activePoiMarkerData;
    highlightedMarkerRef.current = highlightedListCard;
    highlightedListCardClickRef.current = highlightedListCardClick;
    if (highlightedListCardClick && highlightedListCard) {
      highlightedListCardClickRef.current = highlightedListCard.isInBounds;
    }
    shapeBoundsRef.current = shapeBounds;
    showYouGotAwayMsgRef.current = showYouGotAwayMsg;
    focusOnLocalityMarkersRef.current = focusOnLocalityMarkers;
    shapeAddressBaloonRef.current = shapeAddressBaloon;
  }, [
    searchMode,
    activeMarkerData,
    highlightedListCard,
    highlightedListCardClick,
    activePoiMarkerData,
    shapeBounds,
    showYouGotAwayMsg,
    focusOnLocalityMarkers,
    shapeAddressBaloon,
  ]);

  // non sarà searchMode alla fine ma:
  // passiamo una dipendenza all'effect per aggiornare idleListener a seconda del tipo di query da comporre
  // sia essa pan&zoom, polygon, circle, nearby
  // l'event listener non viene duplicato, ma semplicemente aggiornato
  useEffect(() => {
    if (!mapEl) return;
    // searchModeRef.current = searchMode;
    // activeMarkerDataRef.current = activeMarkerData?.close ? null : activeMarkerData;
    // activePoiMarkerDataRef.current = activePoiMarkerData;
    // highlightedMarkerRef.current = highlightedListCard;
    // highlightedListCardClickRef.current = highlightedListCardClick;

    // if (highlightedListCardClick && highlightedListCard) {
    //   highlightedListCardClickRef.current = highlightedListCard.isInBounds;
    // }

    mapEl.addListener('click', closeAllMapOverlayers);
    mapEl.addListener('center_changed', closeCardsAndPois);
    mapEl.addListener('zoom_changed', closeCardsAndPois);
    mapEl.addListener('idle', idleListener);    
  }, [
    mapEl,
    // searchMode,
    // activeMarkerData,
    // highlightedListCard,
    // highlightedListCardClick,
    // activePoiMarkerData,
  ]);

  return { query };
};
