import {
  iconCanc,
  iconBack,
  iconPencil,
  iconFlag,
  animatedLoader,
  addressBookmark,
  addressBookmarked,
} from '../constants';

export const getHighestPoint = shape => shape.getPath().getArray().map((vertex) => {
  return vertex.lat();
}).reduce((e, t, o, n) => {
  if (t > n[e]) {
    return o;
  }
  return e;
}, 0);

export const getLowestPoint = shape => shape.getPath().getArray().map((vertex) => {
  return vertex.lat();
}).reduce((e, t, o, n) => {
  if (t < n[e]) {
    return o;
  }
  return e;
}, 0);

export const getEasternmostPoint = shape => shape.getPath().getArray().map((vertex) => {
  return vertex.lng();
}).reduce((e, t, o, n) => {
  if (t < n[e]) {
    return o;
  }
  return e;
}, 0);  

export const getWesternmostPoint = shape => shape.getPath().getArray().map((vertex) => {
  return vertex.lng();
}).reduce((e, t, o, n) => {
  if (t > n[e]) {
    return o;
  }
  return e;
}, 0); 

export const pxToLatLng = (xCoords, yCoords, map) => {
  const projection = map.getProjection();
  const bounds = map.getBounds();
  if (!projection || !bounds) {
    return null;
  }
  const NE = bounds.getNorthEast();
  const SW = bounds.getSouthWest();
  const NE_ToPoint = projection.fromLatLngToPoint(NE);
  const SW_ToPoint = projection.fromLatLngToPoint(SW);
  const scale = Math.pow(2, map.getZoom());
  return projection.fromPointToLatLng(new google.maps.Point(xCoords / scale + SW_ToPoint.x, yCoords / scale + NE_ToPoint.y));
};

export const mouseCoordsToLatLng = (ev, map, device = '', isSafari = false) => {
  if (ev && map) {
    const t = map.getDiv().getBoundingClientRect();

    const correctEvt = ev.touches && !!ev.touches[0]
      ? ev.touches[0]
      : ev;

    let o = correctEvt.clientX;
    let e = correctEvt.clientY;

    // let a = o;
    // let b = e;

    // a -= t.left;
    // b -= t.top;

    // console.log({
    //   device,
    //   mapDiv: t,
    //   wInnerHeight: window.innerHeight,
    //   screenHeight: screen.availHeight,
    //   clientX: o,
    //   clientY: e,
    //   fakeClientX: a,
    //   fakeClientY: b,
    //   screen,
    // });
    
    // console.log(device !== 'smartphone', isSafari, window.innerHeight === screen.height)

    if (
      window.innerHeight !== screen.height
      // fix per ios in incognito 
      // che comunica un valore di screen height non standard perchè non dichiara l'altezza della barra degli indirizzi del browser
      || (device !== 'smartphone' && isSafari && window.innerHeight === screen.height)
    ) {
      o -= t.left;
      e -= t.top;
    }
    
    return pxToLatLng(o, e, map);
  }
  return null;
};

export const latLngToPx = (latLng, map) => {
  const projection = map.getProjection();
  const bounds = map.getBounds();
  const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
  const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
  const scale = Math.pow(2, map.getZoom());
  const worldPoint = projection.fromLatLngToPoint(latLng);
  return [Math.floor((worldPoint.x - bottomLeft.x) * scale), Math.floor((worldPoint.y - topRight.y) * scale)];
};

export const getPolygonBounds = (polygon, LatLngBounds) => {
  const bounds = new LatLngBounds();
  polygon.getPath().getArray().forEach(v => bounds.extend(v));
  return bounds;
};

export const drawCircle = (point, radius, dir, LatLng, debugFrom) => {
  try {
    const d2r = Math.PI / 180; // degrees to radians
    const r2d = 180 / Math.PI; // radians to degrees
    const earthsradius = 3963; // 3963 is the radius of the earth in miles
  
    const points = 128;
  
    // find the raidus in lat/lon
    const rlat = (radius / earthsradius) * r2d;
    const rlng = rlat / Math.cos(point.lat() * d2r);
  
    let start;
    let end;
  
    const extp = [];
  
    if (dir === 1) {
      start = 0;
      end = points + 1; // one extra here makes sure we connect the path
    } else {
      start = points + 1;
      end = 0;
    }
  
    for (let i = start;
      (dir === 1 ? i < end : i > end); i = i + dir) {
      const theta = Math.PI * (i / (points / 2));
      const ey = point.lng() + (rlng * Math.cos(theta)); // center a + radius x * cos(theta)
      const ex = point.lat() + (rlat * Math.sin(theta)); // center b + radius y * sin(theta)
      extp.push(new LatLng(ex, ey));
    }

    return extp;
  } catch (error) {
    console.log(`drawCircle error from ${debugFrom || 'unknown'}: `, error);
  }
  return [];
};

export const sliderValues = (min, max, step) => {
  const values = [];
  for (let x = min; x <= max; x += step) {
    values.push(x);
  }
  return values;
};

export const setMarkerContent = ({
  start = false,
  cancel = false,
  vertex = false,
  isSmall = false,
  undo = false,
  edit = false,
  center = false,
  centerMsg = false,
  poly = false,
  animated = false,
  value = null,
  handle = false,
}) => {
  const v = document.createElement('div');
  v.classList.add('csa_mapsearch__draw__vertex', 'is-rel');

  const cont = document.createElement('div');
  if (start) {
    cont.classList.add('csa_mapsearch__draw__vertex__cont', 'c-bg--w', 'b--m', 'c-br--primary');
  } else if (vertex) {
    const className = isSmall ? 'csa_mapsearch__draw__vertex__sqr__sml' : 'csa_mapsearch__draw__vertex__sqr';
    cont.classList.add(className, 'c-bg--w', 'c-br--secondary', 'is-rel');
  } else if (cancel) {
    cont.classList.add('csa_mapsearch__draw__vertex__canc', 'c-bg--w');
    cont.innerHTML = iconCanc;
  } else if (undo) {
    cont.classList.add('csa_mapsearch__draw__vertex__undo', 'c-bg--secondary');
    cont.innerHTML = iconBack;
  } else if (edit) {
    cont.classList.add('csa_mapsearch__draw__vertex__edit', 'pt--xs', 'pb--xs', 'pl--s', 'pr--ms', 'c-txt--w', 'tp-s--s', 'tp-w--m', 'c-bg--secondary');
    cont.innerHTML = `${iconPencil} ${value || 'Modifica area'}`;
  } else if (center) {    
    cont.classList.add('csa_mapsearch__draw__vertex__center');
    if (handle) {
      cont.classList.add('csa_mapsearch__draw__vertex__center__drag');
    } else {
      cont.innerHTML = iconFlag;
    }
  } else if (centerMsg) {
    cont.classList.add('csa_mapsearch__draw__vertex__centermsg', 'tp-s--m', 'tp-w--m', 'c-txt--f0');
    if (poly) {
      cont.classList.add('poly');
    }
    cont.innerHTML = animated ? animatedLoader : centerMsg;
  } else if (handle) {    
    const className = 'csa_mapsearch__draw__vertex__drag';
    cont.classList.add(className);
  }
  
  v.appendChild(cont);
  return v;
};

export const getBounds = (bounds) => {      
  if (bounds) {
    const boundsNE = bounds.getNorthEast();
    const boundsSW = bounds.getSouthWest();
    const nw = [boundsNE.lat(), boundsSW.lng()];
    const se = [boundsSW.lat(), boundsNE.lng()];
    return [nw, se];
  }
  return [0, 0];
};

export const addressBaloon = (data, favorite, action) => {
  const cont = document.createElement('div');
  cont.classList.add('csamrk--bln', 'csamrk--pin--active', 'is-rel');

  const popup = document.createElement('div');
  popup.classList.add('csamrk--pin__details', 'tp-w--m');
  const title = document.createElement('p');
  title.classList.add('tp--ell', 'c-txt--f0', 'tp-s--s');
  if (data.favorite) {
    title.classList.add('tp-w--l');
  }
  title.innerHTML = data.name;
  const text = document.createElement('p');
  text.classList.add('tp--ell', 'c-txt--f0', 'tp-s--s');
  text.innerHTML = data.parent;

  const arrow = document.createElement('div');
  arrow.classList.add('csamrk--pin__arrow');

  const bookmarkIcon = data.favorite ? addressBookmarked : addressBookmark;
  const button = document.createElement('div');
  button.classList.add('csamrk--pin__button');
  button.innerHTML = bookmarkIcon;

  button.onclick = (e) => {
    e.stopPropagation();
    const payload = data.favorite ? favorite : data;
    action(payload);
  };

  popup.append(title, text, arrow, button);
  cont.append(popup);

  return cont;
};