import React, { PureComponent, useContext } from 'react';
import PropTypes from 'prop-types';

import openLoginModal from 'shared-utils/src/login/openLoginModal';
import sleep from 'shared-utils/src/sleep';

import PortalHeader from 'shared-components/components/PortalHeader';
import PortalHeaderHP from 'shared-components/components/PortalHeaderHP';
import Footer from 'shared-components/components/Footer/lazy';

import {
  createLoginModal,
  createConsentRequestModal,
  createConsentMandatoryModal,
} from '@components/modals/factories';
import Modal from '@async-components/Modal';

import ConsentModal from './ConsentModal';
import SaveAddressDialog from '../searchMap/components/layout/SaveAddressDialog';
import HandleAddressesModal from '../searchMap/components/layout/HandleAddressesModal';

const propTypes = {
  device: PropTypes.string,
  store: PropTypes.instanceOf(Object),
  config: PropTypes.instanceOf(Object),
  children: PropTypes.instanceOf(Object),
  modal: PropTypes.bool,
  user: PropTypes.instanceOf(Object),
  userLogIn: PropTypes.func,
  userLogOut: PropTypes.func,
  updateGlobalState: PropTypes.func,
  globalReducer: PropTypes.func,
  globalSelector: PropTypes.func,
  pageData: PropTypes.object,
  pageType: PropTypes.string,
  globalState: PropTypes.object,
  viewportHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileOs: PropTypes.string,
  browserBackButton: PropTypes.func,
};

const defaultProps = {
  device: '',
  store: {},
  config: {},
  children: [],
  modal: false,
  pageData: {},
  pageType: '',
  user: {},
  globalState: {},
  userLogIn: () => { },
  userLogOut: () => { },
  updateGlobalState: () => { },
  globalReducer: () => { },
  globalSelector: () => { },
  viewportHeight: null,
  mobileOs: null,
  browserBackButton: null,
};

export const CtxContainer = React.createContext();
export const useCtxGlobal = () => useContext(CtxContainer);

class Container extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modal: {},
      wh: null,
      user: props.user || {},
      handleAddressesModalData: null,
      saveAddressDialogData: null,
    };
    this.refModal = React.createRef();
    this.contextValue = {};
  }

  componentDidMount() {
    window.addEventListener('popstate', this.onBackButtonEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onBackButtonEvent);
  }

  onBackButtonEvent = (e) => {
    const { modal } = this.state;
    const { browserBackButton, pageData: { filtersDialog } } = this.props;

    if (filtersDialog) {
      e.preventDefault();
      return;
    }

    if (Object.keys(modal).length) {
      e.preventDefault();
      this.updateModal({ forceClose: true });
      return;
    }

    if (browserBackButton) {
      e.preventDefault();
      browserBackButton();
      return;
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {};
    const {
      user = {},
      device,
      mobileOs,
    } = nextProps;
    if (
      (
        user.username
        && (user.username !== prevState.user.username)
      ) || (
        user.formData
        && prevState.user.formData
        && user.formData.consent !== prevState.user.formData.consent
      )
    ) {
      state.user = user;
    }

    if (device === 'smartphone' && mobileOs === 'ios' && prevState.wh === null) {
      state.wh = window.innerHeight;
    }

    return state;
  }

  getCurrentUrl = () => {
    const { store: { location }, config: { siteUrl } } = this.props;
    // proviamo a prenderlo dallo store redux
    let currentUrl = location ? `${location.pathname}${location.search}` : null;
    // se non esiste nello store redux proviamo a prenderlo da window
    if (!currentUrl && typeof window !== 'undefined') {
      currentUrl = `${window.location.pathname}${window.location.search}`;
    }
    // se siamo rimasti a secco, ritorniamo la hp
    if (!currentUrl) {
      currentUrl = siteUrl;
    }
    return currentUrl;
  }

  renderModal = () => {
    const { modal } = this.state;
    return (
      <Modal
        title={modal.title || null}
        className={modal.className || null}
        content={modal.content || null}
        container={modal.container ? data => modal.container(data) : null}
        open={!!Object.keys(modal).length}
        actions={modal.actions || null}
        inlineActions={modal.inlineActions || null}
        footer={modal.footer || null}
        forceClose={modal.forceClose || false}
        onClose={this.closeModal}
        width={modal.width || null}
        height={modal.height || null}
        minHeight={modal.minHeight || null}
        compactHeader={modal.compactHeader || false}
        afterClose={modal.afterClose || null}
        header={modal.header || true}
        headerNoShadow={modal.headerNoShadow || false}
        fullContent={modal.fullContent || false}
        fullScreen={modal.fullScreen || false}
        version={modal.version || null}
        borderRadius={modal.borderRadius || null}
        lockscreenCustomCls={modal.lockscreenCustomCls || null}
        trackOnClose={modal.trackOnClose || null}
        trackOnBack={modal.trackOnBack || null}
        disableOvrl={modal.disableOvrl || null}
        disableHistoryBackOnClose={modal.disableHistoryBackOnClose || false}
      />
    );
  }

  openModal = async (props, opts = {}) => {
    if (!opts.doNotPushHistory) {
      sleep(300).then(() => window.history.pushState({}, null, null));
    }
    opts.close && await this.closeModal();
    this.setState({
      modal: {
        ...props,
        v2: 1,
        close: this.closeModal,
      },
    });
  }

  updateModal = async (props) => {
    const { modal } = this.state;
    this.setState({
      modal: modal ? {
        ...modal,
        ...props,
      } : modal,
    });
  }

  makeModalClose = () => {
    const { modal } = this.state;
    this.setState({
      modal: {
        ...modal,
        forceClose: true,
      },
    });
  }

  closeModal = async func => sleep(201).then(() => {
    if (func && typeof func === 'function') {
      return func();
    }
    return this.setState({ modal: {} });
  });


  getUser = () => {
    const { user } = this.props;
    return user;
  }

  getUserLoginPayload = ({ postLoginAction = null, from = null, position = null } = {}) => {
    const {
      device,
      mobileOs,
      viewportHeight,
      config: {
        reCAPTCHA,
        cookiesConfig,
        siteUrl,
        api: {
          account,
          authBasePrefix,
        },
      },
      userLogIn,
    } = this.props;

    return {
      device,
      mobileOs,
      viewportHeight,
      portalDomain: siteUrl,
      accountDomain: account,
      authBasePrefix,
      logIn: userLogIn,
      onLogInComplete: this.closeModal,
      postLoginAction,
      cookiesConfig,
      reCAPTCHA,
      from,
      position,
      createConsentRequestModal,
      createConsentMandatoryModal,
    };
  }

  userLogin = (clb, from, position) => {
    const { pageData: { showSynchingDialog } } = this.props;

    const closeModalAction = payload => this.closeModal(payload.close);

    let postLoginAction = null;
    if (clb) {
      postLoginAction = clb;
    } else if (showSynchingDialog) {
      postLoginAction = showSynchingDialog;
    }

    const loginModalData = this.getUserLoginPayload({ postLoginAction, from, position });

    openLoginModal({
      openModalAction: this.openModal,
      closeModalAction,
      createLoginModal,
      loginModalData,
    });
  };

  showHandleAddressesModal = (data = null) => this.setState({ handleAddressesModalData: data });

  showSaveAddressDialog = (address = null) => this.setState({ saveAddressDialogData: address });

  render() {
    const {
      config,
      pageData,
      pageType,
      appReducer,
      globalReducer,
      globalSelector,
      updateGlobalState,
      globalState,
      device,
      mobileOs,
      orientation,
      iosLth12,
      store: {
        home,
        search,
        searchMap,
        agentPro,
        account,
        subscription,
        qrcodeHandler,
        cercacasa,
        deactivation,
        deactivationAlerts,
        manageConsents,
        removeMailAndConsents,
        agencyHome,
        cercaintornoate,
      },
      children,
      user,
      userLogIn,
      userLogOut,
      viewportWidth,
      viewportHeight,
      modal: propsModal,
      showConsentModal,
    } = this.props;

    const {
      // scrollCoords,
      modal: stateModal,
      handleAddressesModalData,
      saveAddressDialogData,
    } = this.state;

    const isHome = Object.keys(home).length && !('found' in home);
    const noHeaderFooter = Object.keys(subscription || {})?.length ||
      Object.keys(account || {})?.length ||
      Object.keys(deactivation || {})?.length ||
      Object.keys(qrcodeHandler || {})?.length ||
      Object.keys(cercacasa || {})?.length ||
      Object.keys(deactivationAlerts || {})?.length ||
      Object.keys(manageConsents || {})?.length ||
      Object.keys(removeMailAndConsents || {})?.length ||
      Object.keys(cercaintornoate || {})?.length;

    const isAuctionHome = isHome && home?.filters?.only_auction;
    const isSrp = Object.keys(search).length;
    const isSrpMap = Object.keys(searchMap).length;
    const isAgentPro = Object.keys(agentPro).length;
    const isAgencyHome = Object.keys(agencyHome).length;
    const isCercacasa = Object.keys(cercacasa).length;
    const isDeactivationAlerts = Object.keys(deactivationAlerts || {}).length;
    const isManageConsents = Object.keys(manageConsents || {}).length;
    const isRemoveMailAndConsents = Object.keys(removeMailAndConsents || {}).length;
    const isCercaIntornoATe = Object.keys(cercaintornoate || {}).length;    

    let model = null;
    if (isHome) {
      model = home;
    } else if (isSrp) {
      model = search;
    } else if (isSrpMap) {
      model = searchMap;
    } else if (isAgentPro) {
      model = agentPro;
    } else if (isAgencyHome) {
      model = agencyHome;
    } else if (isCercacasa) {
      model = cercacasa;
    } else if (isDeactivationAlerts) {
      model = deactivationAlerts;
    } else if (isManageConsents) {
      model = manageConsents;
    } else if (isRemoveMailAndConsents) {
      model = removeMailAndConsents;
    } else if (isCercaIntornoATe) {
      model = cercaintornoate;
    }

    const modal = propsModal || stateModal ? this.renderModal() : null;
    let containerCssClassName = 'casait';
    if (device !== 'desktop') {
      containerCssClassName += ' touchscreen';
      if (pageData.openSelect) {
        containerCssClassName += ' hdrnosel';
      }
    }
    if (mobileOs === 'android') {
      containerCssClassName += ' android';
    }
    const userUrls = {};
    userUrls.accounthost = config.api.account;
    userUrls.authBasePrefix = config.api.authBasePrefix;
    userUrls.userhost = config.apiEndPoints.mycasaUser;
    userUrls.links = config.mycasaLinks;
    const isAuth = user.isAuthenticated;

    const contextValue = {
      openModal: this.openModal,
      updateModal: this.updateModal,
      closeModal: this.closeModal,
      isMobile: device && device.indexOf('smartphone') >= 0,
      isDesktop: device && device.indexOf('desktop') >= 0,
      isSmallMobile: device && device.indexOf('smartphone') >= 0 && viewportWidth < 360,
      iosLth12,
      viewportHeight,
      updateGlobalState,
      globalState,
      appReducer,
      globalReducer,
      globalSelector,
      pageType,
      model,
      user,
      userConf: {
        userLogIn,
        userUrls,
      },
      isAuth,
      siteUrl: config.siteUrl,
      mobileOs,
      orientation,
      constantsCopy: config.constantsCopy,
      logIn: this.userLogin,
      getUserLoginPayload: this.getUserLoginPayload,
      getUser: this.getUser,

      handleAddressesModalData,
      showHandleAddressesModal: this.showHandleAddressesModal,

      saveAddressDialogData,
      showSaveAddressDialog: this.showSaveAddressDialog,

      ...pageData,
    };

    if (isSrpMap) {
      return (
        <CtxContainer.Provider value={contextValue}>
          <div className={containerCssClassName}>
            {children}
            {modal}
            <HandleAddressesModal
              isOpen={handleAddressesModalData}
              close={this.showHandleAddressesModal}
            />
            <SaveAddressDialog
              isOpen={saveAddressDialogData}
              close={this.showSaveAddressDialog}
            />
          </div>
        </CtxContainer.Provider>
      );
    }

    const footerComp = (
      <Footer
        is404={pageType === 'notfound'}
        domain={config.siteUrl}
        fromPortal
      />
    );

    const Header = isHome && !isAuctionHome
      ? PortalHeaderHP
      : PortalHeader;

    const headerComp = !isHome
      ? (
        <div className="csa_header-container">
          <Header
            user={{
              ...user,
              displayName: user.displayname || user.displayName,
            }}
            logIn={this.userLogin}
            logOut={userLogOut}
            // eslint-disable-next-line no-unneeded-ternary
            // isFixed={isSrp && device === 'desktop' ? true : false}
            portalDomain={config.siteUrl}
            accountDomain={userUrls.accounthost}
            authBasePrefix={userUrls.authBasePrefix}
            myCasaDomain={userUrls.userhost}
            mobileOs={mobileOs}
            fromPortal
          />
        </div>
      ) : (
        <Header
          user={{
            ...user,
            displayName: user.displayname || user.displayName,
          }}
          logIn={this.userLogin}
          logOut={userLogOut}
          portalDomain={config.siteUrl}
          accountDomain={userUrls.accounthost}
          authBasePrefix={userUrls.authBasePrefix}
          myCasaDomain={userUrls.userhost}
          mobileOs={mobileOs}
          fromPortal

        />
      );

    const header = noHeaderFooter ? null : headerComp;
    const footer = noHeaderFooter ? null : footerComp;
    return (
      <CtxContainer.Provider value={contextValue}>
        <div className={containerCssClassName}>
          {header}
          {children}
          {modal}
          {footer}
          <ConsentModal
            showConsentModal={showConsentModal}
            isMobile={device === 'smartphone'}
            user={user}
            hasToShowModalConsent={user?.hasToShowModalConsent}
            accountDomain={userUrls.accounthost}
            openModal={this.openModal}
          />
          <HandleAddressesModal
            isOpen={handleAddressesModalData}
            close={this.showHandleAddressesModal}
          />
          <SaveAddressDialog
            isOpen={saveAddressDialogData}
            close={this.showSaveAddressDialog}
          />
        </div>
      </CtxContainer.Provider>
    );
  }
}
Container.propTypes = propTypes;
Container.defaultProps = defaultProps;
export default Container;
