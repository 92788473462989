import React, {
  useRef,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import Back from 'shared-vectors/icons/ModalBack';
import Close from 'shared-vectors/icons/MapToolsClose';
import Localization from 'shared-vectors/icons/MapLocalization';
import LocalizationIos from 'shared-vectors/icons/MapLocalizationIos';
import CloseCirc from 'shared-vectors/icons/CloseCirc';
import MagnifyingGlass from 'shared-vectors/icons/MagnifyingGlass';
import { Icon } from 'shared-vectors';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import ReactSlider from 'shared-components/components/ReactSlider';

import integerWithThousandSeparator from 'shared-utils/src/integerWithThousandSeparator';

import {
  DRAWING_ACTIONS_MAP,
  DRAWING_ACTIONS_COPY,
  animated,
  Arrow,
} from '../../../../constants';

import getAddress from '../../../../helpers/getAddress';
import decorateMapLocality from '../../../../helpers/decorateMapLocality';

import QbBtn from '../../../layout/QbBtn';
import GeoLocationBtn from '../../../layout/GeoLocationBtn';
import GoogleSearch from '../../GoogleSearch';
import SearchAroundYouControlCircle from './SearchAroundYouControlCircle';

const Controls = ({
  map,
  center,
  searchMode,
  setSearchMode,
  mobileOs,
  device,
  libraries,
  createCircle,
  deleteCircle,
  radius,
  radiusRange,
  updateCircleRadius,
  afterUpdateCircleRadius,
  availableResults,
  availablePoi,
  confirmCircle,
  revertCircle,
  drawingData,
  hasDrawing,
  updateFiltersToAddToMapQuery,
  setDrawingDataAddress,
  viewportWidth,
  isSearchAroundYou,
  openQbFiltersPanel,
  qbGoToSRP,
  searchArounProps,
  qbSSearchAction,
  controlsVariant,
  isShowSaveAddressDialogOpen,
  showSaveAddressDialog,
  isHandleAddressesModalOpen,
  showHandleAddressesModal,
  updateSearchAddressRef,
}) => {
  const gSearchTrigger = useRef(null);
  const [isGSearchOn, setIsGSearchOn] = useState(false);
  const [searchAddress, setSearchAddress] = useState(drawingData?.searchAddress || null);
  const [gSearchError, setGSearchError] = useState('');

  const updateSearchAddress = (addr) => {
    setSearchAddress(addr);
    updateSearchAddressRef(addr);
  };

  useEffect(() => {
    if (searchMode) {
      updateSearchAddress(drawingData?.searchAddress || null);
    }
  }, [searchMode]);

  const activateGoogleSearch = () => {
    gSearchError && setGSearchError('');
    setIsGSearchOn(true);
  };

  const erase = (e) => {
    e.stopPropagation();
    deleteCircle();
    updateSearchAddress(null);
  };

  const shapeFilter = (radius && (center && !center.delete)) ? {
    geocircle: {
      circle: [
        {
          distance: radius,
          center: [center.lat(), center.lng()],
        },
      ],
    },
    q: null,
    geobounds: null,
    geopolygon: null,
    nearby: null,
  } : {};

  const startSearch = () => {
    if (controlsVariant === 'only-confirm-ssearch') {
      qbSSearchAction({
        mapLocations: decorateMapLocality({}),
        mapFilters: shapeFilter,
        mapDrawingData: {
          action: DRAWING_ACTIONS_MAP.circle,
          circle: {
            radius,
            center: [center?.lat(), center?.lng()],
          },
          searchAddress,
          fromSSearch: true,
        },
      });
    } else if (qbGoToSRP) {
      qbGoToSRP({ shapeFilter, forceMap: true });
    } else {
      confirmCircle({ searchAddress, ar: availableResults, clickFrom: 'QB_MainButton' });
    }
  };

  const close = () => {
    if (drawingData && drawingData.action === DRAWING_ACTIONS_MAP.circle) {
      revertCircle();
    } else if (hasDrawing) {
      deleteCircle();
      updateSearchAddress(null);
      setDrawingDataAddress(null);
      setSearchMode(null);
    } else {
      updateSearchAddress(null);
      setDrawingDataAddress(null);
      setSearchMode(null);
    }
    isGSearchOn && setIsGSearchOn(false);
  };

  const resetGSearchError = () => setGSearchError('');

  const gSearchAction = ({
    name,
    parent,
    location,
    error,
  }) => {
    if (error) {
      setGSearchError(<>Non è possibile trovare la tua posizione al momento.<br />Riprova o usa la barra di ricerca.</>);
    } else {
      const { core: { LatLng } } = libraries;
      if (hasDrawing) {
        deleteCircle(true);
      }
      createCircle({
        center: new LatLng({ lat: location.lat, lng: location.lng }),
        doSetCenter: false,
      });

      updateSearchAddress({ name, parent });
    }
  };

  useEffect(() => {
    const getAddressFromCircleCenter = async (coords) => {
      const { geocoding: { Geocoder } } = libraries;
      const service = new Geocoder();
      const data = await service.geocode({ location: coords });
      const { results } = data;
      const [place] = results;
      const { address_components, geometry: { location, viewport } } = place;
      const address = getAddress(address_components);
      const { num, addr, loc } = address;

      const resultObj = {
        name: `${addr}${num ? ` ${num}` : ''}`,
        parent: loc,
        location: JSON.parse(JSON.stringify(location)),
        viewport: JSON.parse(JSON.stringify(viewport)),
      };

      updateSearchAddress({ name: resultObj.name, parent: resultObj.parent, number: num });
      if (drawingData && !drawingData.searchAddress) {
        setDrawingDataAddress({ name: resultObj.name, parent: resultObj.parent });
      }
    };

    if (center) {
      if (center.delete) {
        updateSearchAddress(null);
      } else {
        getAddressFromCircleCenter(center);
      }
    }
  }, [center]);

  let searchButtonCont = 'Nessun risultato';
  if (availableResults?.loading) {
    searchButtonCont = animated();
  } else if (availableResults?.value) {
    searchButtonCont = controlsVariant
      ? <>Conferma (<span className="tp-w--l">{availableResults.value}</span> risultati)</>
      : `Vedi ${availableResults.value} risultati`;
  }

  const actAsSmartphone = viewportWidth < 1024;

  const canSearch = !availableResults?.loading;

  return (
    <>
      {
        isSearchAroundYou ?
          <SearchAroundYouControlCircle
            searchAddress={searchAddress}
            radius={radius}
            radiusRange={radiusRange}
            startSearch={searchArounProps.goToSrpFromSAY}
            updateCircleRadius={updateCircleRadius}
            afterUpdateCircleRadius={afterUpdateCircleRadius}
            updateFiltersToAddToMapQuery={updateFiltersToAddToMapQuery}
            searchButtonCont={searchButtonCont}
            searchArounProps={searchArounProps}
            actAsSmartphone={actAsSmartphone}
          /> :
          <>
            <div className="csa_mapsearch__bod__over__layer__top">
              <div className="csa_mapsearch__bod__over__layer__top__hdr c-bg--w mb--s">
                <div className="csa_mapsearch__bod__over__layer__top__hdr__close c-txt--secondary is-clickable" onClick={close}>
                  <Icon name={actAsSmartphone ? Back : Close} width={actAsSmartphone ? null : '16px'} fill />
                </div>
                <img
                  className="csa_mapsearch__bod__over__layer__top__hdr__icon"
                  src="https://www.casa.it/external-resources/map/around.svg"
                  alt={DRAWING_ACTIONS_COPY.around.desktop}
                  width="100"
                  height="80"
                />
                <div className="csa_mapsearch__bod__over__layer__top__hdr__claim">
                  <p className="csa_mapsearch__bod__over__layer__top__hdr__claim--fst tp-s--l tp-w--l c-txt--f0">{DRAWING_ACTIONS_COPY.around.mobile}</p>
                  <p className="csa_mapsearch__bod__over__layer__top__hdr__claim--sec tp-s--m tp-w--s c-txt--f0">{DRAWING_ACTIONS_COPY.around.secondary}</p>
                </div>
              </div>
              <div
                ref={gSearchTrigger}
                className={`csa_mapsearch__bod__over__layer__gsearchtrigger pt--s pr--s pb--s pl--s c-bg--w is-clickable${searchAddress ? ' compiled' : ''}`}
                onClick={activateGoogleSearch}
              >
                <GridItem behavior="fixed" className="csa_mapsearch__bod__over__layer__gsearchtrigger__lens">
                  <Icon
                    name={MagnifyingGlass}
                    width="24px"
                    className="mr--xs"
                    fill
                  />
                </GridItem>
                <GridItem className={`csa_mapsearch__bod__over__layer__gsearchtrigger__txt tp-s--l tp-w--s c-txt--${searchAddress ? 'f0' : 'f5'} tp--ell`}>{searchAddress ? <>{searchAddress.name}, {searchAddress.parent}</> : 'Cerca indirizzo di partenza'}</GridItem>
                {searchAddress ? (
                  <GridItem behavior="fixed" className="csa_mapsearch__bod__over__layer__gsearchtrigger__btn ml--ms c-txt--f5 is-rel is-clickable" onClick={erase}>
                    <div className="csa_mapsearch__bod__over__layer__gsearchtrigger__btn__click csa_mapsearch__bod__over__layer__gsearchtrigger__btn--erase">
                      <Icon name={CloseCirc} />
                    </div>
                  </GridItem>
                ) : null}
                {(!searchAddress && actAsSmartphone) ? (
                  <GeoLocationBtn
                    action={gSearchAction}
                    className="ml--ms"
                    core={libraries?.core}
                    geocoding={libraries?.geocoding}
                    childrenClassName="csa_mapsearch__bod__over__layer__gsearchtrigger__btn"
                  >
                    <GridItem behavior="fixed" className="csa_mapsearch__bod__over__layer__gsearchtrigger__btn ml--ms c-txt--f0 is-rel is-clickable">
                      <div className="csa_mapsearch__bod__over__layer__gsearchtrigger__btn__click pl--ms">
                        <Icon name={mobileOs === 'ios' ? LocalizationIos : Localization} width="24px" fill />
                      </div>
                    </GridItem>
                  </GeoLocationBtn>
                ) : null}
              </div>
              {!searchAddress ? (
                <Grid>
                  <GridItem behavior="fixed" className="csa_mapsearch__bod__over__layer__searchbaloon p--ms c-bg--secondary is-rel">
                    <Arrow className="csa_mapsearch__bod__over__layer__searchbaloon__arr" />
                    <p className="tp-s--s c-txt--w">
                      Cerca indirizzo o tocca il punto intorno a cui cercare sulla mappa
                    </p>
                    {actAsSmartphone ? (
                      <>
                        <p className="mt--m tp-s--s c-txt--w">Vuoi cercare intorno a te?</p>
                        <GeoLocationBtn
                          action={gSearchAction}
                          core={libraries?.core}
                          geocoding={libraries?.geocoding}
                          childrenClassName="csa_mapsearch__bod__over__layer__searchbaloon__btn"
                          className="csa_mapsearch__bod__over__layer__searchbaloon__btn mt--s c-bg--w b--s c-br--f0"
                          gSearchError={gSearchError}
                          resetGSearchError={resetGSearchError}
                        >
                          <Grid className="pl--s pt--xs pb--xs" align="center">
                            <GridItem behavior="fixed">
                              <Icon name={mobileOs === 'ios' ? LocalizationIos : Localization} className="mr--xs" width="24px" fill />
                            </GridItem>
                            <GridItem className="tp-s--s tp-w--m c-txt--f0">
                              Usa la tua posizione
                            </GridItem>
                          </Grid>

                        </GeoLocationBtn>
                      </>
                    ) : null}
                  </GridItem>
                </Grid>
              ) : null}
              {(!searchAddress && !actAsSmartphone) ? (
                <>
                  <p className="csa_mapsearch__bod__over__layer__glbtn--p pt--ms pb--ms tp-s--s c-txt--f0 tp-a--c">oppure</p>
                  <GeoLocationBtn
                    action={gSearchAction}
                    core={libraries?.core}
                    geocoding={libraries?.geocoding}
                    childrenClassName="csa_mapsearch__bod__over__layer__glbtn"
                    className="csa_mapsearch__bod__over__layer__glbtn c-bg--w b--s is-clickable c-txt--f5"
                    gSearchError={gSearchError}
                    resetGSearchError={resetGSearchError}
                  >
                    <Grid className="pl--s pt--xs pb--xs pr--ms" align="center">
                      <GridItem behavior="fixed">
                        <Icon name={mobileOs === 'ios' ? LocalizationIos : Localization} className="mr--xs" width="24px" fill />
                      </GridItem>
                      <GridItem className="tp-s--s tp-w--m">
                        Usa la tua posizione
                      </GridItem>
                    </Grid>
                  </GeoLocationBtn>
                </>
              ) : null}
              {isGSearchOn ? (
                <GoogleSearch
                  map={map}
                  mobileOs={mobileOs}
                  device={device}
                  places={libraries?.places}
                  core={libraries?.core}
                  geocoding={libraries?.geocoding}
                  searchTrigger={gSearchTrigger.current}
                  isSearching={isGSearchOn}
                  setIsSearching={setIsGSearchOn}
                  className="csa_mapsearch__bod__over__layer__gsearch"
                  action={gSearchAction}
                  geolocationTrackPosition="MapTool"
                  isQBuilder={!!qbSSearchAction || !!qbGoToSRP}
                  isShowSaveAddressDialogOpen={isShowSaveAddressDialogOpen}
                  showSaveAddressDialog={showSaveAddressDialog}
                  isHandleAddressesModalOpen={isHandleAddressesModalOpen}
                  showHandleAddressesModal={showHandleAddressesModal}
                />
              ) : null}
            </div>
            <div className="csa_mapsearch__bod__over__layer__bot">
              {searchAddress ? (
                <div className="csa_mapsearch__draw__radiusSlider pt--ms pr--m pb--ms pl--m c-bg--w">
                  <p className="csa_mapsearch__draw__radiusSlider__text tp-s--l tp-w--s tp-a--c">Entro <span className="tp-w--m">{integerWithThousandSeparator(radius)} m.</span> da {searchAddress.name}, {searchAddress.parent}</p>
                  <ReactSlider
                    value={radiusRange.indexOf(radius)}
                    min={0}
                    max={radiusRange.length - 1}
                    className="csa_mapsearch__draw__slider"
                    thumbClassName="csa_mapsearch__draw__slider__thumb"
                    trackClassName="csa_mapsearch__draw__slider__track"
                    onChange={value => updateCircleRadius(radiusRange[value])}
                    onAfterChange={afterUpdateCircleRadius}
                  />
                  <Grid className="csa_mapsearch__bod__over__layer__bot__btns" align="center">
                    {!controlsVariant ? (
                      <QbBtn
                        searchAddress={searchAddress}
                        availableResults={availableResults}
                        availablePoi={availablePoi}
                        shapeFilter={shapeFilter}
                        updateFiltersToAddToMapQuery={updateFiltersToAddToMapQuery}
                        openQbFiltersPanel={openQbFiltersPanel}
                      />
                    ) : null}
                    <GridItem className="csa_mapsearch__bod__over__layer__bot__btn">
                      <div className={`csa_mapsearch__bod__over__layer__bot__btn tp-s--l tp-w--l c-txt--w c-bg--primary b--s c-br--primary tp-a--c is-clickable${!canSearch ? ' disabled' : ''}`} onClick={startSearch}>
                        {searchButtonCont}
                      </div>
                    </GridItem>
                  </Grid>
                </div>
              ) : null}
            </div>
            {!actAsSmartphone ? (
              <>
                <div
                  className="csa_mapsearch__bod__over__layer__dskhandle is-clickable"
                  onClick={close}
                />
                <Grid className="csa_mapsearch__bod__over__layer__reset" justify="center">
                  <GridItem behavior="fixed" className="csa_mapsearch__bod__over__layer__reset__btn pr--m pl--m tp-s--l tp-w--m b--s tp-a--c c-txt--secondary is-clickable" onClick={close}>
                    Annulla
                  </GridItem>
                </Grid>
              </>
            ) : null}
          </>
      }
    </>
  );
};

export default Controls;

Controls.propTypes = {
  map: PropTypes.instanceOf(Object),
  center: PropTypes.instanceOf(Object),
  searchMode: PropTypes.string,
  mobileOs: PropTypes.string,
  device: PropTypes.string,
  viewportWidth: PropTypes.number,
  libraries: PropTypes.instanceOf(Object),
  drawingData: PropTypes.instanceOf(Object),
  radius: PropTypes.number,
  radiusRange: PropTypes.instanceOf(Array),
  availableResults: PropTypes.instanceOf(Object),
  hasDrawing: PropTypes.bool,
  setSearchMode: PropTypes.func,
  createCircle: PropTypes.func,
  deleteCircle: PropTypes.func,
  updateCircleRadius: PropTypes.func,
  afterUpdateCircleRadius: PropTypes.func,
  confirmCircle: PropTypes.func,
  revertCircle: PropTypes.func,
  updateFiltersToAddToMapQuery: PropTypes.func,
  setDrawingDataAddress: PropTypes.func,
  isSearchAroundYou: PropTypes.bool,
  openQbFiltersPanel: PropTypes.func,
  qbGoToSRP: PropTypes.func,
  searchArounProps: PropTypes.instanceOf(Object),
  controlsVariant: PropTypes.func,
  qbSSearchAction: PropTypes.func,
  availablePoi: PropTypes.instanceOf(Object),
  updateSearchAddressRef: PropTypes.func,
};

Controls.defaultProps = {
  map: null,
  center: null,
  searchMode: null,
  mobileOs: '',
  device: '',
  viewportWidth: 0,
  libraries: null,
  drawingData: null,
  radius: 0,
  radiusRange: [],
  availableResults: null,
  hasDrawing: false,
  setSearchMode: () => { },
  createCircle: () => { },
  deleteCircle: () => { },
  updateCircleRadius: () => { },
  afterUpdateCircleRadius: () => { },
  confirmCircle: () => { },
  revertCircle: () => { },
  updateFiltersToAddToMapQuery: () => { },
  setDrawingDataAddress: () => { },
  isSearchAroundYou: false,
  openQbFiltersPanel: null,
  qbGoToSRP: null,
  searchArounProps: {},
  qbSSearchAction: null,
  controlsVariant: null,
  availablePoi: null,
  updateSearchAddressRef: () => {},
};
